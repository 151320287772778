<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-search-box>
          <template slot="search">
            <div class="col-12">
              <c-select
                :comboItems="domainItems"
                type="search"
                itemText="domainNm"
                itemValue="domainCd"
                name="domain"
                label="코드 도메인"
                v-model="searchParam.srchDomainCd"
              ></c-select>
            </div>
          </template>
        </c-search-box>
        <c-table
          ref="grpTable"
          title="코드그룹"
          tableId="grpTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :columns="grpGrid.columns"
          :isExcelDown="false"
          :data="grpGrid.data"
          @rowClick="getCodeMst"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <c-btn label="검색" icon="search"  @btnClicked="getCodeGroup"/>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="mstTable"
          title="코드 마스터"
          tableId="mstTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :columns="mstGrid.columns"
          :isExcelDown="false"
          :data="mstGrid.data"
          selection="multiple"
          rowKey="code"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <c-btn label="추가" icon="add" @btnClicked="addrow" v-if="editable && isSelectGrp" />
            <c-btn label="저장" icon="save" @btnClicked="saveMst" v-if="editable && isSelectGrp" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable && isSelectGrp" />
          </template>
        </c-table>
      </div>
    </div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">코드</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="hiddenMstCd" autofocus @keyup.enter="setMst" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="취소" @click="cancelMst" />
          <q-btn flat label="추가" @click="setMst" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'code-master',
  data() {
    return {
      editable: true,
      prompt: false,
      searchParam: {
        srchDomainCd: null,
      },
      hiddenMstCd: '',
      isSelectGrp: false,
      selectedGroupCd: '',
      domainItems: [],
      domainlistUrl: '',
      grouplistUrl: '',
      mstSaveUrl: '',
      mstDeleteUrl: '',
      grpGrid: {
        columns: [
          {
            name: 'codeGrpCd',
            field: 'codeGrpCd',
            label: '그룹 코드',
            align: 'center',
            style: 'width:50%',
            sortable: true,
          },
          {
            name: 'codeGrpNm',
            field: 'codeGrpNm',
            label: '그룹명',
            style: 'width:50%',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      mstGrid: {
        columns: [
          {
            name: 'code',
            field: 'code',
            label: '코드',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'codeName',
            field: 'codeName',
            label: '코드명',
            align: 'left',
            style: 'width:300px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'attrVal1',
            field: 'attrVal1',
            label: '추가속성1',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'attrVal2',
            field: 'attrVal2',
            label: '추가속성2',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            style: 'width:60px',
            align: 'center',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.domainlistUrl = selectConfig.sys.code.domain.list.url;
      this.grouplistUrl = selectConfig.sys.code.group.list.url;
      this.mstlistUrl = selectConfig.sys.code.mst.list.url;
      this.mstSaveUrl = transactionConfig.sys.code.mst.save.url;
      this.mstDeleteUrl = transactionConfig.sys.code.mst.delete.url;
      this.getCodeDomain();
      this.getCodeGroup();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    getCodeDomain() {
      this.$http.url = this.domainlistUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.domainItems = _result.data;
      },);
    },
    getCodeGroup() {
      this.rowRemoveSelect();
      this.isSelectGrp = false;
      this.mstGrid.data = [];
      this.$http.url = this.$format(this.grouplistUrl, this.searchParam.srchDomainCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grpGrid.data = _result.data;
      },
      () => {
      });
    },
    getCodeMst(row) {
      this.isSelectGrp = true;
      this.selectedGroupCd = row.codeGrpCd;
      this.$http.url = this.$format(this.mstlistUrl, this.selectedGroupCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.mstGrid.data = _result.data;
      },
      () => {
      });
    },
    saveMst() {
      let checkItem = ['code', 'codeName', 'sortOrder']
      let isConti = true;
      this.$_.forEach(this.mstGrid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [코드, 코드명, 순번]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.mstGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.mstSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
                this.getCodeMst({codeGrpCd: saveData[0].codeGrpCd});
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addrow() {
      this.prompt = true;
    },
    cancelMst() {
      this.hiddenMstCd = '';
      this.prompt = false;
    },
    setMst() {
      this.prompt = false;
      this.mstGrid.data.push({
        editFlag: 'C',
        codeGrpCd: this.selectedGroupCd,
        code: this.hiddenMstCd,
        codeName: '',
        attrVal1: '',
        attrVal2: '',
        attrVal3: '',
        attrVal4: '',
        useFlag: 'Y',
        sortOrder: '',
      })
      this.hiddenMstCd = '';
    },
    removeRow() {
      let selectData = this.$refs['mstTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.mstDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getCodeMst({codeGrpCd: selectData[0].codeGrpCd});
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
